<script setup lang="ts">
  import type {IOffersWidget} from '~/utils/services/api';

  const props = defineProps<{
      widget: IOffersWidget
  }>();
  const {data} = useAFCatalogArticles(props.widget.articles, false, props.widget.type);
</script>

<template>
    <CpSectionHomeFeaturedProducts v-if="data" :articles="data.articles" :title="widget.title" />
</template>
