<script lang="ts" setup>
  import {useI18n} from 'vue-i18n';
  import {onBeforeUnmount, ref} from 'vue';
  import {DateTime} from 'luxon';
  import useCpBem from '~/composables/useCpBem';
  import CpSectionStrip from '~/components/cp/CpSection/CpSectionStrip/CpSectionStrip.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import type {ICpArticle} from '~/types';

  defineProps<{
      articles: ICpArticle[],
      title?: string,
  }>();

  const {b, e} = useCpBem('cp-section-home-featured-products');
  const {t} = useI18n();
  const interval = ref<string>();
  const countdownInterval = ref<any>();

  const timer = () => {
    countdownInterval.value = setInterval(() => {
      const currentTime = DateTime.now().endOf('day');
      const diffTime = currentTime.diff(DateTime.now(), ['hours', 'minutes', 'seconds']);
      const hours = diffTime.hours.toFixed(0).padStart(2, '0');
      const minutes = diffTime.minutes.toFixed(0).padStart(2, '0');
      const seconds = diffTime.seconds.toFixed(0).padStart(2, '0');
      interval.value = `${hours}h ${minutes}m ${seconds}s`;
    }, 1000);
  };

  onMounted(() => {
    timer();
  });

  onBeforeUnmount(() => {
    clearInterval(countdownInterval.value);
  });
</script>

<template>
    <div :class="b">
        <div :class="e`header-container`">
            <div :class="e`header-sub-container`">
                <CpFeaturedAnimation :class="e('animation')" />
                <CpText variant="heading-h2" type="neutral-dark">
                    {{ title ?? t('todayFeatured') }}
                </CpText>
            </div>
            <CpText variant="heading-h3-fake" type="mkt-dark" data-testid="counter">
                {{ interval }}
            </CpText>
        </div>
        <CpSectionStrip
            kind="cta6"
            :items-to-show="articles"
            button
            card-type="home"
            :label="t('seeAllFeatured')"
            button-href="/Destacados-del-dia/"
        />
    </div>
</template>

<style scoped lang="scss">
  @import "assets/style/global.scss";
  .cp-section-home-featured-products {
    &__header-container {
      border-top: $cp-border-standard;
      border-bottom: $cp-border-standard;
      display: flex;
      justify-content: space-between;
      padding: $cp-gutter;
      align-items: center;
    }
    &__header-sub-container {
      display: flex;
    }
    &__animation {
      padding-right: $cp-gutter-small;
    }
  }
</style>

<i18n locale="es-US">
  todayFeatured: '!Destacados del día!'
  seeAllFeatured: 'Ver todas las ofertas'
</i18n>
